<template>
	<div class="gg-container" v-loading.fullscreen.lock="fullscreenLoading" style="overflow: visible ;padding: 0">
		<el-button type="danger" @click="closeDrawer"
							 style="position: absolute; display: flex; justify-content: center; align-items: center; border: 0; border-radius: 4px 0 0 4px; top: 140px; left: -40px;width: 40px; height: 40px;">
			<i class="iconfont icon-cha" style="font-size: 24px"></i>
		</el-button>
		<el-tabs v-model="activeName" @tab-click="handleClick" style="font-size: 14px;box-sizing: border-box"
						 class="tab-container">
			<el-tab-pane label="就诊情况" name="first"
									 style="font-size: 14px; font-weight: 400; background-color: #f7f7f7;">
				<el-row :gutter="10" style="margin: 20px 10px 20px 10px;background-color: #f7f7f7;">
					<el-col :span="16" style="background-color: white;min-height: 760px;padding: 20px 10px 20px 20px;">
						<div class="grid-content bg-purple" style="display: flex;  align-items: center;">
							<div class="required"></div>
							<span>接诊详细信息</span>
						</div>
						<!--患者区域-->
						<!--左边问诊编号科室, 右边病情描述-->
						<div class="grid-content bg-purple">
							<el-row>
								<el-col :span="12">
									<el-row>
										<el-col :span="6">
											<div class="grid-content bg-purple relation-container" style="color: #777">
												问诊编号
											</div>
										</el-col>
										<el-col :span="6">
											<div class="grid-content bg-purple relation-container number-align">
												{{info.order_sn}}
											</div>
										</el-col>
										<el-col :span="12">
											<div class="grid-content bg-purple relation-container"></div>
										</el-col>
									</el-row>
									<el-row>
										<el-col :span="6">
											<div class="grid-content bg-purple relation-container" style="color: #777">
												科室
											</div>
										</el-col>
										<el-col :span="6">
											<div class="grid-content bg-purple relation-container number-align">
												{{info.depart_name}}
											</div>
										</el-col>
										<el-col :span="12">
											<div class="grid-content bg-purple relation-container"></div>
										</el-col>
									</el-row>
								</el-col>
								
								<el-col :span="12">
									<el-row>
										<el-col :span="6">
											<div class="grid-content bg-purple relation-container" style="color: #777">
												病情描述
											</div>
										</el-col>
										<el-col :span="18">
											<div class="grid-content bg-purple relation-container">
												{{info.desc}}
											</div>
										</el-col>
									</el-row>
								</el-col>
							</el-row>
						</div>
						<div class="grid-content bg-purple">
							<el-row>
								<el-col :span="3">
									<div class="grid-content bg-purple relation-container" style="color: #777">
										来源
									</div>
								</el-col>
								<el-col :span="3">
									<div class="grid-content bg-purple relation-container number-align">
										{{from_sources[info.from_source]}}
									</div>
								</el-col>
								<el-col :span="6">
									<div class="grid-content bg-purple relation-container"></div>
								</el-col>
								<el-col :span="3">
									<div class="grid-content bg-purple relation-container" style="color: #777">
										患病时长
									</div>
								</el-col>
								<el-col :span="3">
									<div class="grid-content bg-purple relation-container">
										{{info.ill_duration}}
									</div>
								</el-col>
							</el-row>
						</div>
						<!--带图片遮罩- 左边患者姓名到患者手机号- 右边图片- 3行 -->
						<div class="grid-content bg-purple">
							<el-row>
								<el-col :span="12">
									<div class="grid-content bg-purple">
										<el-row>
											<el-col :span="6">
												<div class="grid-content bg-purple relation-container"
														 style="color: #777">
													患者姓名
												</div>
											</el-col>
											<el-col :span="6">
												<div class="grid-content bg-purple relation-container">
													{{info.pat_name}}
												</div>
											</el-col>
											<el-col :span="12">
												<div class="grid-content bg-purple relation-container">
												</div>
											</el-col>
										</el-row>
										<el-row>
											<el-col :span="6">
												<div class="grid-content bg-purple relation-container"
														 style="color: #777">
													患者性别
												</div>
											</el-col>
											<el-col :span="6">
												<div class="grid-content bg-purple relation-container">
													{{info.pat_gender}}
												</div>
											</el-col>
											<el-col :span="12">
												<div class="grid-content bg-purple relation-container">
												</div>
											</el-col>
										</el-row>
										<el-row>
											<el-col :span="6">
												<div class="grid-content bg-purple relation-container"
														 style="color: #777">
													患者手机号
												</div>
											</el-col>
											<el-col :span="6">
												<div class="grid-content bg-purple relation-container">
													{{info.pat_phone}}
												</div>
											</el-col>
											<el-col :span="8">
												<div class="grid-content bg-purple relation-container">
												</div>
											</el-col>
											<el-col :span="2">
												<div class="grid-content bg-purple relation-container">
												
												</div>
											</el-col>
										</el-row>
									</div>
								</el-col>
								<el-col :span="12">
									<div class="grid-content bg-purple-light">
										<el-row>
											<el-col :span="6">
												<div class="grid-content bg-purple relation-container"
														 style="color: #777">
													病历图片
												</div>
											</el-col>
											<el-col :span="12">
												<div class="grid-content bg-purple relation-container"
														 style="padding: 0">
													<!--图片遮罩效果-->
													<div @mouseenter="enter('drawerPic1')" @mouseleave="leave()"
															 @click="under(info.photo, 'drawerPic1')"
															 style="position: relative"
															 v-if="info.photo && info.photo.length > 0">
														<transition name="fade">
															<div v-if=" 'drawerPic1' === isPicIdx"
																	 style="position: absolute;background-color: rgba(101, 101, 101, .3); width: 80px; height: 80px; z-index: 3999">
															</div>
														</transition>
														<i v-show="'drawerPic1' === isPicIdx " class="el-icon-zoom-in"
															 style="position: absolute;top: 50%;left: 50%; transform: translate(-50%, -50%); font-size:26px; color: #fff; width: 30px; height: 30px; z-index: 4000"></i>
														<el-image ref="drawerPic1" style="width: 80px; height: 80px;"
																			:z-index=zIndex
																			:src="info.photo instanceof Array ? info.photo[0] : '' "
																			:preview-src-list="info.photo instanceof Array ? info.photo : [] ">
														</el-image>
													</div>
													<div v-else>
														暂无
													</div>
												</div>
											</el-col>
										</el-row>
									</div>
								</el-col>
							</el-row>
						</div>
						<!--医生区域-->
						<div class="grid-content bg-purple margin-t-10">
							<el-row>
								<el-col :span="3">
									<div class="grid-content bg-purple relation-container" style="color: #777">
										医师姓名
									</div>
								</el-col>
								<el-col :span="2">
									<div class="grid-content bg-purple relation-container number-align">
										{{info.doctors_name}}
									</div>
								</el-col>
								<el-col :span="7">
									<div class="grid-content bg-purple relation-container"></div>
								</el-col>
								<el-col :span="3">
									<div class="grid-content bg-purple relation-container" style="color: #777">
										基本诊断
									</div>
								</el-col>
								<el-col :span="6">
									<div class="grid-content bg-purple relation-container">
										{{info.debate}}
									</div>
								</el-col>
							</el-row>
						</div>
						<!--带图片遮罩- 左边医师编号医师职称接诊状态,- 右边图片- 3行 -->
						<div class="grid-content bg-purple">
							<el-row>
								<el-col :span="12">
									<div class="grid-content bg-purple">
										<el-row>
											<el-col :span="6">
												<div class="grid-content bg-purple relation-container"
														 style="color: #777">
													医师编号
												</div>
											</el-col>
											<el-col :span="6">
												<div class="grid-content bg-purple relation-container">
													{{info.doctors_id}}
												</div>
											</el-col>
											<el-col :span="12">
												<div class="grid-content bg-purple relation-container">
												</div>
											</el-col>
										</el-row>
										<el-row>
											<el-col :span="6">
												<div class="grid-content bg-purple relation-container"
														 style="color: #777">
													医生职称
												</div>
											</el-col>
											<el-col :span="6">
												<div class="grid-content bg-purple relation-container">
													{{info.professional}}
												</div>
											</el-col>
											<el-col :span="12">
												<div class="grid-content bg-purple relation-container">
												</div>
											</el-col>
										</el-row>
										<el-row>
											<el-col :span="6">
												<div class="grid-content bg-purple relation-container"
														 style="color: #777">
													接诊状态
												</div>
											</el-col>
											<el-col :span="6">
												<div class="grid-content bg-purple relation-container">
													{{orderStatus[info.order_status]}}
												</div>
											</el-col>
											<el-col :span="8">
												<div class="grid-content bg-purple relation-container">
												</div>
											</el-col>
											<el-col :span="2">
												<div class="grid-content bg-purple relation-container">
												
												</div>
											</el-col>
										</el-row>
									</div>
								</el-col>
								<el-col :span="12">
									<div class="grid-content bg-purple-light">
										<el-row>
											<el-col :span="6">
												<div class="grid-content bg-purple relation-container"
														 style="color: #777">
													电子病历
												</div>
											</el-col>
											<el-col :span="12">
												<div class="grid-content bg-purple relation-container"
														 style="padding: 0">
													<!--图片遮罩效果-->
													<div @mouseenter="enter('drawerPic2')" @mouseleave="leave()"
															 @click.stop="under(info.emr_pic, 'drawerPic2')"
															 style="position: relative"
															 v-if="info.emr_pic && info.emr_pic.length > 0">
														<transition name="fade">
															<div v-if="'drawerPic2' === isPicIdx "
																	 style="position: absolute;background-color: rgba(101, 101, 101, .3); width: 90px; height: 90px; z-index: 3999">
															</div>
														</transition>
														<i v-show="'drawerPic2' === isPicIdx " class="el-icon-zoom-in"
															 style="position: absolute;top: 50%;left: 50%; transform: translate(-50%, -50%); font-size:26px; color: #fff; width: 30px; height: 30px; z-index: 4000"></i>
														<el-image ref="drawerPic2" style="width: 90px; height: 90px;"
																			:z-index=zIndex
																			:src="info.emr_pic instanceof Array ? info.emr_pic[0] : '' "
																			:preview-src-list="info.emr_pic instanceof Array ? info.emr_pic : [] ">
														</el-image>
													</div>
													<div v-else>
														暂无
													</div>
												</div>
											</el-col>
										</el-row>
									</div>
								</el-col>
							</el-row>
						</div>
						<div class="grid-content bg-purple">
							<el-row>
								<el-col :span="3">
									<div class="grid-content bg-purple relation-container" style="color: #777">
										监管状态
									</div>
								</el-col>
								<el-col :span="3">
									<div class="grid-content bg-purple relation-container number-align">
										{{superviseStatus[info.supervise_status]}}
									</div>
								</el-col>
								<el-col :span="6">
									<div class="grid-content bg-purple relation-container"></div>
								</el-col>
								<el-col :span="3">
									<div class="grid-content bg-purple relation-container" style="color: #777">
									
									</div>
								</el-col>
								<el-col :span="3">
									<div class="grid-content bg-purple relation-container">
									
									</div>
								</el-col>
							</el-row>
						</div>
						<div class="grid-content bg-purple">
							<el-row>
								<el-col :span="3">
									<div class="grid-content bg-purple relation-container" style="color: #777">
										现病史
									</div>
								</el-col>
								<el-col :span="9">
									<div class="grid-content bg-purple relation-container">
										{{info.now_past}}
									</div>
								</el-col>
								<el-col :span="3">
									<div class="grid-content bg-purple relation-container" style="color: #777">
										医生建议
									</div>
								</el-col>
								<el-col :span="9">
									<div class="grid-content bg-purple relation-container">
										{{info.advices}}
									</div>
								</el-col>
							</el-row>
						</div>
						<!-- 问诊时间 -->
						<div class="grid-content bg-purple margin-t-10">
							<el-row>
								<el-col :span="3">
									<div class="grid-content bg-purple relation-container" style="color: #777">
										开始时间
									</div>
								</el-col>
								<el-col :span="9">
									<div class="grid-content bg-purple relation-container">
										{{info.started_at}}
									</div>
								</el-col>
							</el-row>
						</div>
						<div class="grid-content bg-purple">
							<el-row>
								<el-col :span="3">
									<div class="grid-content bg-purple relation-container" style="color: #777">
										结束时间
									</div>
								</el-col>
								<el-col :span="6">
									<div class="grid-content bg-purple relation-container">
										{{info.ended_at}}
									</div>
								</el-col>
								<el-col :span="6">
									<div class="grid-content bg-purple relation-container"></div>
								</el-col>
								<el-col :span="2">
									<div class="grid-content bg-purple relation-container">
									
									</div>
								</el-col>
								<el-col :span="1">
									<div class="grid-content bg-purple relation-container">
									
									</div>
								</el-col>
							</el-row>
						</div>
						
<!--						<div class="follow-up-container">
							
							<div class="required"></div>
							<span style="margin-top: 1px">随访时间</span>
							<span style="margin-top: 1px" class="follow-up-select-wrapper">随访次数</span>
							<el-select v-model="value" placeholder="请选择" size="mini" clearable>
								<el-option
									v-for="item in options"
									:key="item.value"
									:label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
						<div class="follow-up-time">
							<div class="title">随访时间</div>
							<div class="content">2021-12-21</div>
						</div>
						<div class="follow-up-content">
							<div class="title">随访内容</div>
							<div class="content">
								<div class="up">第一次随访</div>
								<div class="down">经过沟通经过沟通经过沟通经过沟通经过沟通经过沟通经过沟通经过沟通经过沟通经过沟通经过沟通经过沟通经
									过沟通经过沟通经过沟通经过沟通经过沟通经过沟通经过沟通经过沟通经过沟通经过沟通经过沟通经过沟通经过沟通经过沟通经过沟通经过沟通经过沟通经过沟通经过沟通经过沟通
								</div>
							</div>
						</div>-->
					</el-col>
					
					<!-- 问诊记录 -->
					<el-col
						style="min-height: 760px;background-color: white;width: 31.5%;margin-left: 15px;padding: 20px 10px 20px 20px;">
						<div class="grid-content bg-purple-light relation-container">
							<div class="required"></div>
							<span>问诊记录</span>
						</div>
						<div class="information" @scroll="scrollLoad($event)" style="overflow:scroll;height: 690px;">
							<div class="" v-for="(item,index) of chatList" :key="index">
								<div class="msg-left" v-if="item.from_type==2 && item.message.type=='TEXT'">
									<div class="msg-img">
										<img :src="dataInfo.doctor_pic" style="width: 30px;">
									</div>
									<div class="msg-text">
										<span style="font-size: 12px;color: #777;">{{dataInfo.doctor_name}}</span>
										<div class="msg-text-b">
											{{item.message.msg}}
										</div>
									</div>
								</div>
								<div class="msg-left" v-if="item.message.type=='PRESCRIPTION'">
									<div class="msg-img">
										<img :src="dataInfo.doctor_pic" style="width: 30px;">
									</div>
									<div class="msg-text">
										<div class="msg-text-b">
											已开具处方
										</div>
									</div>
								</div>
								<div class="msg-left" v-if="item.message.type=='MSGTIPS'">
									<div class="msg-img">
										<img src="https://pic.hngegejk.cn/ggjk_16484353953798" style="width: 30px;">
									</div>
									<div class="msg-text">
										<span style="font-size: 12px;color: #777;">系统消息</span>
										<div class="msg-text-b">
											{{item.message.msg}}
										</div>
									</div>
								</div>
								<div class="msg-right" v-if="item.from_type==3 && item.message.type=='TEXT'">
									<div class="msg-text">
										<span style="font-size: 12px;color: #777;">{{dataInfo.pat_name}}</span>
										<div class="msg-text-b">
											{{item.message.msg}}
										</div>
									</div>
									<div class="msg-img">
										<img :src="dataInfo.pat_pic" style="width: 30px;">
									</div>
								</div>
							
							</div>
						</div>
					</el-col>
				</el-row>
			</el-tab-pane>
			<el-tab-pane label="电子处方" name="second" style="margin: 20px 10px 20px 20px;min-height: 760px;"
									 v-if="Object.keys(presInfo).length > 0">
				<div style="padding: 20px 10px;font-size: 14px;">
					<el-row>
						<el-col :span="6">
							<div class="grid-content bg-purple relation-container">
								<div style="display: flex;  align-items: center;">
									<div class="required"></div>
									<span>处方信息</span>
								</div>
							</div>
						</el-col>
						<el-col :span="16">
							<div class="grid-content bg-purple-light"></div>
						</el-col>
						<el-col :span="2" style="display: flex;justify-content: flex-end;">
							<el-dropdown trigger="click">
								<span class="el-dropdown-link">
									...
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item>审核</el-dropdown-item>
									<el-dropdown-item>删除</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12" class="grid-content bg-purple relation-container">
							<span class="title-pre-l">
								处方编号
							</span>
							<span>
								{{presInfo.pres_sn}}
							</span>
						</el-col>
						<el-col :span="12" class="grid-content bg-purple relation-container">
							<span class="title-pre-l">
								科室
							</span>
							<span>
								{{presInfo.depart_name}}
							</span>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12" class="grid-content bg-purple relation-container">
							<span class="title-pre-l">
								姓名
							</span>
							<span>
								{{presInfo.patient_name}}
							</span>
						</el-col>
						<el-col :span="12" class="grid-content bg-purple relation-container">
							<span class="title-pre-l">
								性别
							</span>
							<span>
								{{presInfo.patient_gender}}
							</span>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12" class="grid-content bg-purple relation-container">
							<div class="title-pre-l">
								电话
							</div>
							<div class="grid-content bg-purple-light relation-container number-align">
								{{presInfo.patient_mobile}}
							</div>
						</el-col>
						<el-col :span="12" class="grid-content bg-purple-light relation-container">
							<div class="title-pre-l">
								诊断
							</div>
							<div>
								{{presInfo.debate}}
							</div>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12" class="grid-content bg-purple relation-container">
							<div class="title-pre-l">
								审核状态
							</div>
							<div class="grid-content bg-purple-light relation-container number-align">
								{{presInfo.examine_status | formatExamineStatus}}
							</div>
						</el-col>
						<el-col :span="12" class="grid-content bg-purple-light relation-container">
							<div class="title-pre-l">
								拒绝原因
							</div>
							<div>
								{{presInfo.reject_reason}}
							</div>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12" class="grid-content bg-purple relation-container">
							<div class="title-pre-l">
								开方医师
							</div>
							<div>
								{{presInfo.doctor_name}}
							</div>
						</el-col>
						<el-col :span="12" class="grid-content bg-purple-light relation-container">
							<div class="title-pre-l">
								审方药师
							</div>
							<div>
								{{presInfo.druggist_one_name}}
							</div>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="2" class="grid-content bg-purple-light relation-container">
							<div class="title-pre-l">
								处方笺
							</div>
						</el-col>
						<el-col :span="10">
							<div>
								<div @mouseenter="enter()" @mouseleave="leave()"
										 @click="under(presInfo.pres_pics, 'drawerPic')" style="position: relative"
										 v-if="presInfo.pres_pics && presInfo.pres_pics.length > 0">
									<transition name="fade">
										<div v-if="seen"
												 style="position: absolute;background-color: rgba(101, 101, 101, .3); width: 90px; height: 90px; z-index: 3999">
										</div>
									</transition>
									<i v-show="seen" class="el-icon-zoom-in"
										 style="position: absolute;top: 50%;left: 50%; transform: translate(-50%, -50%); font-size:26px; color: #fff; width: 30px; height: 30px; z-index: 4000"></i>
									<el-image ref="drawerPic" style="width: 90px; height: 90px;" :z-index=zIndex
														:src="presInfo.pres_pics instanceof Array ? presInfo.pres_pics[0] : '' "
														:preview-src-list="presInfo.pres_pics instanceof Array ? presInfo.pres_pics : [] ">
									</el-image>
								</div>
								<div v-else class="grid-content bg-purple-light relation-container"
										 style="margin-left: 2px">
									暂无
								</div>
							</div>
						
						</el-col>
					</el-row>
					
					<!--成药方子表格-->
					<el-row v-if="tableDataDrawer.length" class="margin-t-30">
						<el-col :span="6">
							<div class="grid-content bg-purple relation-container">
								<div style="display: flex;  align-items: center;">
									<div class="required"></div>
									<span>成药方子</span>
								</div>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="grid-content bg-purple-light"></div>
						</el-col>
					</el-row>
					<el-table :data="tableDataDrawer" v-if="tableDataDrawer.length" border style="width: 100%">
						<el-table-column prop="drug_name" label="名称" align="center" width="180">
						</el-table-column>
						<el-table-column prop="sn" label="编号" align="center" width="180">
						</el-table-column>
						<el-table-column prop="unit" label="规格" align="center" width="120">
						</el-table-column>
						<el-table-column prop="dosage_unit" label="剂型" align="center" width="240">
						</el-table-column>
						<el-table-column prop="count" label="数量" align="center" width="80">
						</el-table-column>
						<el-table-column prop="drug_price" label="单价" align="center" width="160">
						</el-table-column>
						<el-table-column prop="usage" label="用药建议" align="center" width="270">
						</el-table-column>
						<el-table-column prop="pres_not" label="是否处方药" align="center" width="160">
						</el-table-column>
					</el-table>
					
					<!-- 中药方子 -->
					<el-row class="margin-t-30" v-if="tcDrugs.length">
						<el-col :span="6">
							<div class="grid-content bg-purple relation-container">
								<div style="display: flex;  align-items: center;">
									<div class="required"></div>
									<span>中药方子</span>
								</div>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="grid-content bg-purple-light"></div>
						</el-col>
					</el-row>
					<el-row v-if="tcDrugs.length">
						<el-col :span="24">
							<div class="grid-content bg-purple relation-container">
								<span style="color: #333333">{{this.tcDrugs.join(' ；')}}</span>
							</div>
						</el-col>
					</el-row>
					<el-row v-if="tcDrugs.length">
						<el-col :span="24">
							<div class="grid-content bg-purple relation-container">
								<span style="color: #333333">用药建议及医嘱: {{info.taboo || '没有'}}</span>
							</div>
						</el-col>
					</el-row>
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import {
  interviewRecord
} from '@/api/informationSearch/index_2.0';
import {
  getPresDetail
} from '@/api/informationSearch';

export default {
  name: "therapyDetail",
  props: {
    id: {
      type: [Number, String]
    },
    type: {
      type: String,
      default: 'add'
    },
    doctorType: {
      type: [Number, String],
      default: 1
    },
    status: {
      type: [Number, String],
      default: 1
    },
    info: {
      type: Object
    },
    diag_id: {
      type: [String, Number]
    },


  },
  computed: {},
  async mounted () {

    await this.getInterviewRecord(this.diag_id, '1')
    await this._getPresDetail(this.id)
  },
  filters: {
    formatExamineStatus (v) {
      if (v === 0) {
        return '待审核'
      } else if (v === 1)
        return '初审通过'
      else if (v === 2) {
        return '复审通过'
      } else {
        return '审核拒绝'
      }
    }
  },
  data () {
    return {
      from_sources: ['患者场景', '医生场景', '慢病场景'],
      examine_status: ['待审核', '一审通过', '二审通过', '拒绝'],
      superviseType: [{
        value: '1',
        label: '图文'
      }, {
        value: '2',
        label: '视频'
      }],
      orderStatus: ['已取消', '待接诊', '接诊中', '已结束'],
      superviseStatus: ['未上传', '待上传', '已上传'],
      fullscreenLoading: false,
      activeName: 'first',
      prescriptionDetail: {}, //电子处方
      chatList: [], //问诊记录列表
      // 图片遮罩相关
      seen: false,
      current: null,
      isPicIdx: null,
      // 默认图片层级
      zIndex: 2000,
      presInfo: {},
      tcDrugs: [],
      tableDataDrawer: [],
      chatListLoading: false,
      pages: {
        page: 1,
        page_size: 15,
        total: ''
      },
      chatListFalse: false,
      options: [],
      value: ''
    }
  },
  methods: {

    scrollLoad (e) {
      if (e.srcElement.offsetHeight + e.srcElement.scrollTop >= e.srcElement.scrollHeight) {
        this.$set(this.pages, 'page', this.pages.page + 1)
        this.getInterviewRecord(this.diag_id, '2')
      }

    },
    //获取问诊记录
    getInterviewRecord (id, type) {
      if (this.chatListFalse) {
        return
      }
      console.log(7777777)
      let params = {
        id: id,
        page: this.pages.page,
        page_size: this.pages.page_size
      }
      interviewRecord(params).then(res => {
        if (res.code == 200) {
          if (type == '1') {
			console.log(res.data.list,233,'问诊记录')
            this.dataInfo = res.data
            this.chatList = res.data.list.reverse()
          } else {
            this.chatList = [...this.chatList, ...res.data.list.reverse()]
          }
          console.log('this.chatList', this.chatList)
        }
        if (!res.data.list.length) {
          this.chatListFalse = true
        }
      })
    },
    _getPresDetail (id) {
      this.listLoading = true
      let params = {
        'pres_id': id
      }
      console.log('getPresDetail', params)
      getPresDetail(params).then(response => {
        let data = response
        if (data.code === 200) {
          this.presInfo = data.data
          console.log('++++++++++++++++')
          if (data.data.tc_drugs) {
            this.tcDrugs = data.data.tc_drugs
            this.tcDrugs = this.tcDrugs.map((v, k, a) => {
              return `${v.drug_name.split(" ").join("")} ${v.count}克`
            })
          }
          this.tableDataDrawer = data.data['w_drugs']
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    handleClick (tab, event) {
      console.log(tab, event);
    },
    enter (index = null) {
      this.seen = true;
      this.current = index;
      this.isPicIdx = index
    },
    leave () {
      this.seen = false;
      this.current = null;
      this.isPicIdx = null;
    },
    under (pics, refPic = 'pic') {
      if (pics.length > 0) {
        this.zIndex = 9999
        this.$refs[refPic].showViewer = true
      }
    },
    closeDrawer () {
      console.log(123)
      this.$emit('closeDrawer', false)
    }
  },
}
</script>

<style scoped lang="scss">
	.gg-container {
		box-sizing: border-box;
		margin: 0;
	}
	
	::v-deep.tabs-container {
		.el-tabs__header {
			padding: 0;
		}
	}
	
	::v-deep .el-tab-pane {
		min-height: 800px;
		overflow: visible;
	}
	
	.margin-t-10 {
		margin-top: 30px;
	}
	
	/* 蓝色方块 */
	.required {
		margin-top: 2px;
		display: inline-block;
		width: 4px;
		height: 14px;
		background-color: #2362FB;
		top: 20px;
	}
	
	.required + span {
		display: inline-block;
		margin-left: 4px;
		vertical-align: top;
		font-weight: bold;
		font-size: 14px;
	}
	
	
	/*说明文字样式*/
	.title-pre-l {
		width: 120px;
		color: #777
	}
	
	/*鼠标滑动,详情颜色改变*/
	.is-hover {
		color: #333;
	}
	
	.is-hover:hover {
		color: #4ebafa
	}
	
	/*问诊记录*/
	.msg-img {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		overflow: hidden;
	}
	
	.information {
		.msg-left {
			display: flex;
			margin-bottom: 10px;
			
			.msg-text {
				font-size: 12px;
				margin-left: 10px;
				
				.msg-text-b {
					margin-top: 5px;
					padding: 10px;
					background-color: #f7f7f7;
					border-radius: 8px;
					max-width: 350px;
					word-break: break-word
				}
			}
			
		}
		
		.msg-right {
			display: flex;
			justify-content: flex-end;
			margin-bottom: 10px;
			
			.msg-text {
				font-size: 12px;
				margin-right: 10px;
				text-align: right;
				
				.msg-text-b {
					margin-top: 5px;
					padding: 10px;
					background-color: #f7f7f7;
					border-radius: 8px;
					max-width: 350px;
					word-break: break-word
				}
			}
		}
	}
	
	
	/*栅格文本居中*/
	.relation-container {
		display: flex;
		/*justify-content:center;!*主轴上居中*!*/
		align-items: center;
		/*侧轴上居中*/
	}
	
	.number-align {
		padding-top: 4px;
	}
	
	/*栅格边框*/
	.el-row {
		
		/*margin-bottom: 20px;*/
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	.el-col {
		border-radius: 4px;
	}
	
	::v-deep .el-tab-pane {
		overflow: auto;
	}
	
	/*测试排版使用颜色*/
	/*.bg-purple-dark {*/
	/*	background: #99a9bf;*/
	/*}*/
	
	/*.bg-purple {*/
	/*	background: #d3dce6;*/
	/*}*/
	
	/*.bg-purple-light {*/
	/*	background: #e5e9f2;*/
	/*}*/
	
	.grid-content {
		border-radius: 4px;
		min-height: 30px;
	}
	
	
	.follow-up-container {
		margin-top: 20px;
		display: flex;
		align-items: center;
		
		.follow-up-select-wrapper {
			margin-left: 30px;
			margin-right: 20px;
			font-weight: bolder;
			color: #777;
		}
	}
	
	.follow-up-time {
		margin-top: 10px;
		display: flex;
		align-items: center;
		justify-content: left;
		
		.title {
			width: 90px;
			font-weight: bolder;
			color: #777;
		}
		
		.content {
		
		}
	}
	
	.follow-up-content {
		margin-top: 10px;
		display: flex;
		align-items: center;
		justify-content: left;
		
		.title {
			width: 90px;
			flex-shrink: 0;
			height: 100px;
			font-weight: bolder;
			color: #777;
		}
		
		.content {
			display: flex;
			justify-content: left;
			flex-direction: column;
			height: 100px;
			
			.up {
			
			
			}
			
			.down {
			
			}
		}
	}
</style>
